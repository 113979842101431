import React from 'react';
import { Link } from 'gatsby-plugin-modal-routing'

const NodeList = (props) => {

    //console.log(props);

  return (
    <>
      <ul className="node_list">
      {
        props.termNodes.map( term_node => (
        <li key={term_node.node_value.nid}>
          <Link 
            to={(() => {
            switch(term_node.node_value.path.langcode) {
              case "en": return `${term_node.node_value.path.alias}`;
              case "fr": return `/fr${term_node.node_value.path.alias}`;
              case "es": return `/es${term_node.node_value.path.alias}`;
              case "ru": return `/ru${term_node.node_value.path.alias}`;
              case "pt-pt": return `/pt-pt${term_node.node_value.path.alias}`;
              case "zh-hans": return `/zh-hans${term_node.node_value.path.alias}`;
              default:  return `${term_node.node_value.path.alias}`;
            }
            }) ()} 
            asModal
          >
            <span className="node-thumbnail"></span>{term_node.node_value.title}
          </Link>
        </li>
        ))
      }
      </ul>
    </>
  );
};

export default NodeList;