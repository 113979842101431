import React from 'react'
import NodeList from '../../components/NodeList/NodeList'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Buttons from "../../components/clipboard"

class ChildTermList extends React.Component {
	constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);

    this.childButtonClick = this.childButtonClick.bind(this);

    this.state = {
    	page_url_data: 'https://growthplatformassets.rsmlink.global'
    }
  }

  setTermParam(termAlias) {
	  var url = new URL(window.location.href);
	  var query_string = url.search;
	  var search_params = new URLSearchParams(query_string); 
	  search_params.set('term', termAlias);
	  url.search = search_params.toString();
	  var new_url = url.toString();
	  return new_url;
	}

	setTermParamUpdate(termAlias) {
	  let new_url = this.setTermParam(termAlias)
	  //this.pageurl = new_url
	  window.history.pushState({path:new_url},'',new_url)
	}

	handleClick(e) {
	  if( e.target.classList.contains('parent-term-thumbnail') ) {
	  	// Does the url have a ?term= url parameter?
	  	if( e.target.closest('.parent-term-container').classList.contains('display_growth_data') ){
	  		// If classList has display_growth_data remove
	    	e.target.closest('.parent-term-container').classList.remove('display_growth_data')

	    } else {
	    	// If classList does not have display_growth_data add to element and remove from any other elements with class
	    	var parentContainersOpen = document.querySelectorAll('.parent-term-container.display_growth_data')
				for(var i = 0; i < parentContainersOpen.length; i++){
					parentContainersOpen[i].classList.remove('display_growth_data')
				}
	    	e.target.closest('.parent-term-container').classList.add('display_growth_data')
	    	let termAlias = e.target.closest('.getTermAlias').getAttribute('term')
		  	//console.log(termAlias)
				this.setTermParamUpdate(termAlias)
	    }
	  }
	}

	childButtonClick(e) {
		// We have two elements that are clickable 
	  if( e.target.classList.contains('child-term-thumbnail') || e.target.classList.contains('child-term-button')){
	  	// Get the attribute term in either element
	  	let termAlias = e.target.getAttribute('term')
	  	// If the term is already open it will have the class "display_growth_data"
	  	if( e.target.closest('.child_term_item').classList.contains('display_growth_data') ){
	  		// Remove open class
	  		e.target.closest('.child_term_item').classList.remove('display_growth_data')
	  		// If we are closing the child term we no longer need it in the url so get the parent attribute and pass to url builder
	  		if( e.target.classList.contains('child-term-thumbnail') ){
	  			termAlias = e.target.closest('.child-term-button').getAttribute('parent');
	  			this.setTermParamUpdate(termAlias)
	  		} else {
	  			termAlias = e.target.getAttribute('parent');
	  			this.setTermParamUpdate(termAlias)
	  		}
	  	} else {
	  		// If the term is not open add the opening class
	  		e.target.closest('.child_term_item').classList.add('display_growth_data')
				this.setTermParamUpdate(termAlias)
	  	}
	  }
  }

  render() {

  	//console.log(this.props)
  	// Set the urls manually
  	var pageurl = '';
  	if(this.props.termLangcode === 'en'){
  		pageurl = "https://growthplatform.rsmlink.global/?term="+this.props.termAlias;
  	} else {
	  	pageurl = "https://growthplatform.rsmlink.global/"+this.props.termLangcode+"?term="+this.props.termAlias;
	  }

	  let videoIframe;
	  //console.log("TEST");
	  //console.log(this.props);
	  if(this.props.termVideo){
	    var videoUrl = "https://player.vimeo.com/video/"+this.props.termVideo
	    videoIframe = <iframe title="Vimeo" src={videoUrl} width="100%" height="400" allow="autoplay; fullscreen"></iframe>
	  } else {
	  	videoIframe = null
	  }

		return (
			<>
				<div className="parent_term" term_id={this.props.tid} >
					<AnchorLink className="getTermAlias" term={this.props.termAlias} offset="100" href={`#${this.props.termAlias}`}>
						<button onClick={this.handleClick} onKeyDown={this.handleClick} className="parent-term-thumbnail" termlink={this.props.termAlias}>
							<span className="thumbnail-inner-border">
								{
									(() => {
		                switch(this.props.termIcon) {
		                  case null: return null;
		                  default:  return <img alt="icon" src={"https://growthplatformassets.rsmlink.global/files/Files_and_images/icons/" + this.props.termIcon} />;
		                }
		              }) ()
								} 
							</span>
						</button>
						<span className="line-border"></span>
						<h2 onClick={this.handleClick} onKeyDown={this.handleClick} className="parent-term-thumbnail" termlink={this.props.termAlias}>{this.props.name}</h2>
					</AnchorLink>
				</div>
				<div className="another-border"></div>
				<div className="term_list_container">
					<div className="term_list_block">
						<div className="special-border-line"></div>
						{
							(() => {
                switch(this.props.termNodes.length > 0) {
                  case true: return <NodeList termNodes={this.props.termNodes} />;
                  default:  return  null;
                }
              }) ()
						} 

						<ul className="parent_list_items">
			      {
			        this.props.termChildren.map( term_children => (
			        <li className="child_term_item" key={term_children.tid}>
			          <a className="child-term-button"  onClick={this.childButtonClick} onKeyDown={this.childButtonClick} term={term_children.alias} parent={this.props.termAlias}>
			          	<button className="child-term-thumbnail" term={term_children.alias}>
			          	<span>
			          		{
											(() => {
				                switch(term_children.icon) {
				                  case null: return null;
				                  default:  return <img src={"https://growthplatformassets.rsmlink.global/files/Files_and_images/icons/" + term_children.icon} />;
				                }

				              }) ()
										} 
			          	</span>
			          	</button>
			          	{term_children.name}
			          </a>
			          
			          {
									(() => {
		                switch(term_children.description.value) {
		                  case null: return null;
		                  default:  return <div className="term-child-description"><div dangerouslySetInnerHTML={{ __html: term_children.description.value }}></div></div>;
		                }
		              }) ()
								} 
								{
									(() => {
		                switch(term_children.nodes.length > 0) {
		                  case true: return <NodeList termNodes={term_children.nodes} />;
		                  default:  return  null;
		                }
		              }) ()
								} 
			        </li>
			        ))
			      }
			      </ul>
		      </div>
		      <div className="term_content_block">
						<h2 term_id={this.props.tid}>{this.props.name}</h2>
						{
							(() => {
                switch(this.props.description.value) {
                  case null: return null;
                  default:  return <div className="term-description" dangerouslySetInnerHTML={{ __html: this.props.description.value }}></div>;
                }
              }) ()
						} 

						{
							(() => {
                switch(videoIframe) {
                  case null: return null;
                  default:  return <div className="term-video">{videoIframe}</div>;
                }
              }) ()
						} 

						<section className="term-buttons">
							<Buttons pagetitle={this.props.name} pageurl={pageurl} langcode={this.props.termLangcode} />
						</section>
					</div>

	      </div>
	    </>
		);
	}
};

export default ChildTermList;
